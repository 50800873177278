// firebaseService.js
import { db,storage } from '../firebase';
import {
    collection,
    addDoc,
    onSnapshot,
    query,
    orderBy,
    getDocs,
    getDoc,
    doc,
    where,
    deleteDoc,
    updateDoc
} from 'firebase/firestore';
import {ref, uploadBytes, getDownloadURL, getStorage, uploadBytesResumable} from 'firebase/storage';


export const uploadImage = (file, folder) => {
    return new Promise((resolve, reject) => {
        const storage = getStorage();
        const storageRef = ref(storage, `${folder}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // 진행 상태를 보고 싶다면 추가 가능
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            },
            (error) => {
                console.error('Upload error:', error);
                reject(error);
            },
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                resolve(downloadURL);
            }
        );
    });
};

// 방명록 메시지 추가 함수
export const addMessage = async (message, workspace) => {
    try {
        // 각 워크스페이스에 맞는 컬렉션에 메시지를 저장
        await addDoc(collection(db, `workspaces/${workspace}/messages`), {
            message,
            timestamp: new Date(),
        });
    } catch (error) {
        console.error("Error adding message: ", error);
    }
};

// 워크스페이스 목록 불러오기 함수
// 특정 워크스페이스의 데이터를 가져오는 함수
export const getWorkspaces = async () => {
    const workspaceCollection = collection(db, 'workspaces');
    // 최신순 정렬 쿼리 추가
    const q = query(workspaceCollection, orderBy('createdAt', 'desc')); // 최신순(desc) 정렬
    const workspaceSnapshot = await getDocs(q);

    console.log('Number of documents in workspace:', workspaceSnapshot.size);

    return workspaceSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

// 특정 워크스페이스에 데이터를 추가하는 함수
// Firestore에 새 워크스페이스 문서를 추가하는 함수
export const createWorkspace = async (workspaceData) => {
    try {
        const docRef = await addDoc(collection(db, 'workspaces'), {
            ...workspaceData,          // 워크스페이스 데이터 (예: 이름 등)
            createdAt: new Date(),
        });
        console.log('Workspace created with ID: ', docRef.id);
        return docRef.id;
    } catch (e) {
        console.error('Error creating workspace: ', e);
        throw e;
    }
};

// 워크스페이스 삭제 함수
export const deleteWorkspace = async (workspaceId) => {
    try {
        const workspaceDoc = doc(db, 'workspaces', workspaceId);
        await deleteDoc(workspaceDoc);
    } catch (error) {
        console.error('Error deleting workspace:', error);
        throw error;
    }
};

// 워크스페이스 업데이트 함수
export const updateWorkspace = async (workspaceId, data) => {
    const workspaceRef = doc(db, 'workspaces', workspaceId);
    await updateDoc(workspaceRef, data);
};


export const subscribeToMessages = async (workspaceId, callback) => {
    try {
        // 3. 해당 워크스페이스 하위의 `messages` 컬렉션 구독
        const messagesCollection = collection(db, `workspaces/${workspaceId}/messages`);
        const messagesQuery = query(messagesCollection, orderBy('timestamp', 'asc'));

        // 4. 실시간 메시지 구독
        return onSnapshot(messagesQuery, (snapshot) => {
            const messages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            callback(messages);
        });
    } catch (error) {
        console.error('Error subscribing to messages:', error);
    }
};

/**
 * 특정 워크스페이스의 배경 이미지와 로고를 가져오는 함수
 * @param {string} workspaceId - 워크스페이스의 문서 ID
 * @returns {Promise<Object>} - 배경 이미지와 로고 데이터를 반환
 */
export const getWorkspaceSettings = async (workspaceId) => {
    try {
        // Firestore에서 해당 워크스페이스 문서를 참조
        const workspaceDocRef = doc(db, 'workspaces', workspaceId);
        const workspaceSnapshot = await getDoc(workspaceDocRef);

        // 문서가 존재하지 않을 경우 처리
        if (!workspaceSnapshot.exists()) {
            console.error(`Workspace with ID ${workspaceId} does not exist.`);
            return null;
        }

        // 문서 데이터 가져오기
        const workspaceData = workspaceSnapshot.data();

        // 배경 이미지와 로고만 반환
        const settings = {
            backgroundImage: workspaceData.backgroundImage || null,
            logo: workspaceData.logo || null,
            description : workspaceData.description || null,
            name :workspaceData.name || null,
            isMessageBoardOpen :workspaceData.isMessageBoardOpen || null,
        };

        return settings;
    } catch (error) {
        console.error('Error fetching workspace settings:', error);
        throw error;
    }
};

// 메시지 삭제 함수
export const deleteMessage = async (workspaceId, messageId) => {
    const messageRef = doc(db, `workspaces/${workspaceId}/messages`, messageId);
    await deleteDoc(messageRef);
};

// 이미지 파일을 Firebase Storage에 업로드하는 함수
// export const uploadImage = async (file, folder) => {
//     if (!file) return null;
//     const storageRef = ref(storage, `${folder}/${file.name}`);
//
//     try {
//         const snapshot = await uploadBytes(storageRef, file);
//         const downloadURL = await getDownloadURL(snapshot.ref);
//         return downloadURL;
//     } catch (error) {
//         console.error('Error uploading image:', error);
//         throw error;
//     }
// };
