// AdminWorkspaceList.js
import React, { useState, useEffect } from 'react';
import {createWorkspace, deleteWorkspace, getWorkspaces, updateWorkspace} from './firebaseService';
import {Box, Button, Container, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ExportMessagesToExcel from "./ExportMessagesToExcel";

const AdminWorkspaceList = () => {
    const [workspaces, setWorkspaces] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

        const fetchWorkspaces = async () => {
            const workspaceList = await getWorkspaces();
            setWorkspaces(workspaceList);
        };
        fetchWorkspaces();

    }, []);

    const handleToggleMessageBoard = async (workspaceId, currentState) => {
        try {
            const newState = !currentState;
            await updateWorkspace(workspaceId, { isMessageBoardOpen: newState }); // Firestore에 상태 업데이트
            setWorkspaces((prev) =>
                prev.map((workspace) =>
                    workspace.id === workspaceId ? { ...workspace, isMessageBoardOpen: newState } : workspace
                )
            );
        } catch (error) {
            console.error('메시지보드 상태 변경 오류:', error);
            alert('메시지보드 상태 변경에 실패했습니다.');
        }
    };

    const handleCreateWorkspaceClick = () => {
        navigate('/admin/workspaces/create');
    };

    const handleEditClick = (workspaceId) => {
        navigate(`/admin/workspaces/edit/${workspaceId}`);
    };

    const handleDeleteClick = async (workspaceId) => {
        if (window.confirm('정말로 삭제하시겠습니까?')) {
            try {
                await deleteWorkspace(workspaceId);
                setWorkspaces(workspaces.filter((workspace) => workspace.id !== workspaceId));
                alert('워크스페이스가 삭제되었습니다.');
            } catch (error) {
                console.error('워크스페이스 삭제 중 오류:', error);
                alert('워크스페이스 삭제에 실패했습니다.');
            }
        }
    };

    return (
        <Container maxWidth="lg" style={{ marginTop: '50px' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h4">워크스페이스 리스트</Typography>
                <Button variant="contained" color="primary" onClick={handleCreateWorkspaceClick}>
                    워크스페이스 생성
                </Button>
            </Box>
            <Typography variant="h4" gutterBottom>
                워크스페이스 리스트
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>워크스페이스 ID</TableCell>
                        <TableCell>워크스페이스 이름</TableCell>
                        <TableCell>메시지보드 상태</TableCell>
                        <TableCell>생성일</TableCell>
                        <TableCell align="center">액션</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {workspaces.map((workspace) => (
                        <TableRow key={workspace.id}>
                            <TableCell>
                                <a
                                    href={`/${workspace.id}?admin=true`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'none', color: 'blue' }}
                                >
                                    {workspace.id}
                                </a>
                            </TableCell>
                            <TableCell>{workspace.name}</TableCell>
                            <TableCell>
                                {workspace.isMessageBoardOpen ? '열림' : '닫힘'}
                            </TableCell>

                            <TableCell>{new Date(workspace.createdAt?.seconds * 1000).toLocaleString()}</TableCell>
                            <TableCell align="center">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleEditClick(workspace.id)}
                                >
                                    수정
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => handleDeleteClick(workspace.id)}
                                >
                                    삭제
                                </Button>
                                <ExportMessagesToExcel workspaceId={workspace.id} />

                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleToggleMessageBoard(workspace.id, workspace.isMessageBoardOpen)}
                                >
                                    {workspace.isMessageBoardOpen ? '메시지 닫기' : '메시지 열기'}
                                </Button>

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Container>
    );
};

export default AdminWorkspaceList;
