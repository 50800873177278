import React, {useEffect, useState} from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    Card,
    CardMedia,
    CardContent, Dialog, DialogContent, CircularProgress
} from '@mui/material';
import {addMessage, getWorkspaceSettings, uploadImage} from "./firebaseService";
import {useParams} from "react-router-dom";

const Guestbook = () => {
    const { workspaceId } = useParams(); // URL에서 workspaceId 가져오기

    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    const [affiliation, setAffiliation] = useState('');
    const [position, setPosition] = useState('');
    const [isAgreed, setIsAgreed] = useState(false);
    const [photo, setPhoto] = useState(null); // 파일 객체
    const [photoPreview, setPhotoPreview] = useState(null); // 이미지 미리보기 URL
    const [isDialogOpen, setIsDialogOpen] = useState(false); // 개인정보 동의 팝업 상태
    const [workspaceSettings, setWorkspaceSettings] = useState({
        backgroundImage: '',
        logo: '',
        isMessageBoardOpen : false,
    }); // 워크스페이스 설정 상태

    const [loading, setLoading] = useState(false); // 로딩 상태 관리



    useEffect(() => {
        // 워크스페이스 설정 가져오기
        const fetchSettings = async () => {
            const settings = await getWorkspaceSettings(workspaceId);
            setWorkspaceSettings(settings);
            console.log(settings)
        };
        fetchSettings();
    }, [workspaceId]);

    const handleDialogOpen = () => {
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setPhoto(file);

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPhotoPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async () => {


        if (!message.trim() || !name.trim()) {
            alert('메시지와 이름을 입력해주세요.');
            return;
        }



        try {
            setLoading(true); // 로딩 시작
            let photoURL = null;

            // Firebase Storage에 이미지 업로드
            if (photo) {
                photoURL = await uploadImage(photo, `workspaces/${workspaceId}/photos`);
            }


            // Firestore에 메시지 저장
            const newMessage = {
                message,
                name,
                affiliation, // 소속
                position, // 직책
                image: photoURL, // 업로드된 사진 URL
                timestamp: new Date(),
            };

            await addMessage(newMessage, workspaceId);

            // 입력 초기화
            setMessage('');
            setName('');
            setAffiliation('');
            setPosition('');
            setPhoto(null);
            setPhotoPreview(null);
            alert('메시지가 저장되었습니다!');
        } catch (error) {
            console.error('Error saving message:', error);
            alert('메시지 저장 중 오류가 발생했습니다.');
        }finally {
            setLoading(false); // 로딩 종료
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 6,
                backgroundImage: `url(${workspaceSettings.backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
            }}
        >
            {/* 로고 */}
            {workspaceSettings.logo && (
                <Box
                    component="img"
                    src={workspaceSettings.logo}
                    alt="Workspace Logo"
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        left: '20px',
                        width : '150px',
                    }}
                />
            )}

            {/* 상단 유의사항 */}
            <Box
                sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    color: 'white',
                    padding: 2,
                    borderRadius: '16px',
                    textAlign: 'center',
                    marginBottom: 2,
                    maxWidth: '90%',
                }}
            >
                <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: 0.5 }}>
                    메시지 작성 시 유의사항
                </Typography>
                <Typography variant="body2">
                    • 욕설이나 비방은 자제해 주세요.<br />
                    • 메시지 내용은 완료 후 수정이 불가능합니다.
                </Typography>
            </Box>

            {/* 메시지 작성 영역 */}
            <Box
                sx={{
                    background: 'linear-gradient(to top, #fff, #f8f8f8)',
                    width: '100%',
                    maxWidth: '600px',
                    padding: 3,
                    borderRadius: '16px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Typography variant="h6" textAlign="center" marginBottom={2} color="gray">
                    3부 행사 소감을 남겨주세요
                </Typography>
                <TextField
                    multiline
                    fullWidth
                    minRows={6}
                    placeholder="메시지를 작성하세요"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    variant="outlined"

                sx={{
                        background: 'white',
                        borderRadius: '8px',
                        marginBottom: 2,
                    }}
                    inputProps={{
                        maxLength: 50,
                    }}
                />
                <Typography variant="caption" color="gray" display="block" textAlign="center" marginBottom={2}>
                    [{message.length}/50]
                </Typography>

                {/* 성명, 소속, 직책 입력 */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 1,
                        marginBottom: 2,
                    }}
                >
                    <TextField
                        fullWidth
                        placeholder="성명"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        variant="outlined"
                        sx={{ background: 'white', borderRadius: '8px' }}
                    />
                    <TextField
                        fullWidth
                        placeholder="소속(선택)"
                        value={affiliation}
                        onChange={(e) => setAffiliation(e.target.value)}
                        variant="outlined"
                        sx={{ background: 'white', borderRadius: '8px' }}
                    />
                    <TextField
                        fullWidth
                        placeholder="직책(선택)"
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                        variant="outlined"
                        sx={{ background: 'white', borderRadius: '8px' }}
                    />
                </Box>

                {/* 사진 업로드 */}
                <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    sx={{
                        textTransform: 'none',
                        marginBottom: 2,
                        borderRadius: '8px',
                    }}
                >
                    사진 추가(선택)
                    <input type="file" accept="image/*" hidden onChange={handleFileChange} />
                </Button>

                {/* 카드 미리보기 */}
                <Card
                    fullWidth
                    sx={{
                        marginBottom: 3,
                        borderRadius: '16px',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                        overflow: 'hidden',
                        background: 'white',
                        whiteSpace: 'pre-line', // 줄바꿈 처리
                    }}
                >
                    {photoPreview && (
                        <CardMedia
                            component="img"
                            image={photoPreview}
                            alt="Photo Preview"
                            sx={{ height: 180, objectFit: 'cover' }}
                        />
                    )}
                    <CardContent>
                        <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                            {message || '메시지 미리보기가 표시됩니다.'}
                        </Typography>
                        <Typography
                            variant="caption"
                            color="text.secondary"
                            sx={{ textAlign: 'center', display: 'block' }}
                        >
                            {name || '작성자 이름'} · {affiliation || '소속'} · {position || '직책'}
                        </Typography>
                    </CardContent>
                </Card>


                {/* 개인정보 동의 */}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isAgreed}
                            onChange={(e) => setIsAgreed(e.target.checked)}
                            sx={{ color: 'primary.main' }}
                        />
                    }
                    label={
                        <Typography variant="caption" color="gray"
                                    onClick={handleDialogOpen}
                        >
                            [필수] 개인정보 수집 및 이용 동의
                        </Typography>
                    }
                />
            </Box>

            {/* 완료 버튼 */}
            <Button
                variant="contained"
                onClick={handleSubmit}
                component="label"
                fullWidth
                disabled={!workspaceSettings.isMessageBoardOpen} // 메시지보드가 닫힌 경우 버튼 비활성화
                sx={{
                    marginTop: 2,
                    textTransform: 'none',
                    marginBottom: 2,
                    borderRadius: '8px',
                }}
            >
                {loading ? '등록 중...' : '메시지 저장'}
            </Button>

            {loading && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // 반투명 배경
                        zIndex: 1000,
                    }}
                >
                    <CircularProgress />
                </Box>
            )}

            {/* 개인정보 동의 팝업 */}
            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                <DialogContent
                    sx={{
                        padding: 3,
                        borderRadius: '16px',
                        backgroundColor: 'white',
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                        개인정보 수집·이용 동의
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: 1 }}>
                        서비스 제공을 위해 아래와 같이 개인정보를 수집·이용하고자 합니다.
                        귀하께서는 아래 내용에 대해 동의를 거부하실 수 있으며, 거부 시 서비스 이용이 제한됩니다.
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: 1, fontWeight: 'bold' }}>
                        개인정보 수집·이용 목적 및 항목
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: 1 }}>
                        <strong>수집 목적:</strong> 디지털 메시지 게시 서비스 제공
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: 1 }}>
                        <strong>수집 항목:</strong> 작성자의 이름, 소속, 직책
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: 1 }}>
                        <strong>보유 및 이용 기간:</strong> 행사 종료 후 30일까지 또는 행사 주최의 별도의 파기 요청이 있는 경우 즉시 파기함
                    </Typography>
                    <Button variant="contained" onClick={handleDialogClose} sx={{ marginTop: 2 }}>
                        닫기
                    </Button>
                </DialogContent>
            </Dialog>

        </Box>
    );
};

export default Guestbook;
