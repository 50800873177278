// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Guestbook from './components/Guestbook';
import GuestbookViewer from './components/GuestbookViewer';
import AdminWorkspaceList from './components/AdminWorkspaceList';
import AdminWorkspaceCreate from './components/AdminWorkspaceCreate.js';
import { Container } from '@mui/material';
import { Helmet } from 'react-helmet';
import AdminWorkspaceEdit from "./components/AdminWorkspaceEdit";

function App() {
    return (
        <>

            <Helmet>
                {/* 기본 메타 태그 */}
                <title>실시간 방명록 ​🫠​💀​🤡​</title>
                <meta name="description" content="실시간으로 방명록을 남겨보세요"/>
                <meta name="keywords" content="방명록, 실시간 방명록, 메시지 남기기"/>
                {/* Open Graph 메타 태그 */}
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="실시간 방명록 ​🫠​💀​🤡​"/>
                <meta property="og:description" content="실시간으로 방명록을 남겨보세요"/>
                <meta property="og:image" content={`${process.env.PUBLIC_URL}/images/thumbnail.webp`}/>
                <meta property="og:url" content={window.location.href}/>

                {/* Twitter 메타 태그 */}
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="실시간 방명록 ​🫠​💀​🤡​"/>
                <meta name="twitter:description" content="실시간으로 방명록을 남겨보세요"/>
                <meta name="twitter:image" content={`${process.env.PUBLIC_URL}/images/thumbnail.webp`}/>
            </Helmet>

            <Router>
                <Container
                    style={{
                        overflow: 'hidden',
                        padding: 0,
                        margin: 0,
                        width: '100%',
                        maxWidth: '100%',
                        textAlign: 'center',
                    }}
                >
                    <Routes>
                        {/* /:workspaceId 경로로 워크스페이스를 동적으로 받음 */}
                        <Route path="/:workspaceId/write" element={<Guestbook />} />
                        <Route path="/:workspaceId" element={<GuestbookViewer />} />
                        <Route path="/admin/workspaces" element={<AdminWorkspaceList />} />
                        <Route path="/admin/workspaces/create" element={<AdminWorkspaceCreate />} />
                        <Route path="/admin/workspaces/edit/:workspaceId" element={<AdminWorkspaceEdit />} />

                    </Routes>
                </Container>
            </Router>
        </>
    );
}

export default App;
